import React, { useEffect } from 'react'
import { compose } from 'redux'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Row } from 'antd'
import { initialize } from 'redux-form'
import { useNavigate } from 'react-router'

// components
import Breadcrumbs from '../../components/Breadcrumbs'
import CategoryParamsForm from './components/CategoryParamsForm'
import { EMPTY_NAME_LOCALIZATIONS } from '../../components/LanguagePicker'

// utils
import { withPermissions } from '../../utils/Permissions'
import { PERMISSION, FORM, PARAMETERS_VALUE_TYPES, PARAMETERS_UNIT_TYPES } from '../../utils/enums'
import { postReq, putReq } from '../../utils/request'

// types
import { IBreadcrumbs, ICategoryParamsForm, PostUrls, PutUrls, RequestPayload } from '../../types/interfaces'

// hooks
import useBackUrl from '../../hooks/useBackUrl'

type PutValuesRequestBody = RequestPayload<PutUrls['/api/b2b/admin/enums/category-parameters/{categoryParameterID}/values/']>
type PostParameterRequestBody = RequestPayload<PostUrls['/api/b2b/admin/enums/category-parameters/']>

const CreateCategoryParamsPage = () => {
	const [t] = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [backUrl] = useBackUrl(t('paths:category-parameters'))

	useEffect(() => {
		dispatch(
			initialize(FORM.CATEGORY_PARAMS, {
				nameLocalizations: EMPTY_NAME_LOCALIZATIONS,
				valueType: PARAMETERS_VALUE_TYPES.ENUM,
				localizedValues: [{ valueLocalizations: EMPTY_NAME_LOCALIZATIONS }],
				values: [{ value: null }]
			})
		)
	}, [dispatch])

	const handleSubmit = async (formData: ICategoryParamsForm) => {
		let values = []
		let unitType: PARAMETERS_UNIT_TYPES | undefined

		if (formData.valueType === PARAMETERS_VALUE_TYPES.TIME) {
			unitType = PARAMETERS_UNIT_TYPES.MINUTES
			values = formData.values
		} else {
			values = formData.localizedValues
		}

		try {
			const reqBody: PostParameterRequestBody = {
				nameLocalizations: formData.nameLocalizations.reduce<PostParameterRequestBody['nameLocalizations']>((acc, nameLocalization) => {
					if (!nameLocalization.value) {
						return acc
					}

					return [...acc, nameLocalization as PostParameterRequestBody['nameLocalizations'][0]]
				}, []),
				valueType: formData.valueType,
				unitType
			}

			const { data } = await postReq('/api/b2b/admin/enums/category-parameters/', { params: {}, reqBody })
			const categoryParameterID = data.categoryParameter.id

			const categoryParameterValues: Partial<PutValuesRequestBody['categoryParameterValues']> = []

			values.forEach((value) => {
				if (unitType === PARAMETERS_UNIT_TYPES.MINUTES && 'value' in value && value.value) {
					categoryParameterValues.push({ value: value.value.toString() })
				}

				if (unitType !== PARAMETERS_UNIT_TYPES.MINUTES && 'valueLocalizations' in value) {
					const valueLocalizations = value.valueLocalizations.filter(
						(localization) => !!localization.value
					) as PutValuesRequestBody['categoryParameterValues'][0]['valueLocalizations']

					if (valueLocalizations?.length) {
						categoryParameterValues.push({
							valueLocalizations
						})
					}
				}
			})

			await putReq('/api/b2b/admin/enums/category-parameters/{categoryParameterID}/values/', {
				params: { path: { categoryParameterID } },
				reqBody: { categoryParameterValues: categoryParameterValues as PutValuesRequestBody['categoryParameterValues'] }
			})
			navigate(t('paths:category-parameters'))
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		}
	}

	const breadcrumbs: IBreadcrumbs = {
		items: [
			{
				name: t('loc:Zoznam parametrov kategórií'),
				link: backUrl
			},
			{
				name: t('loc:Vytvoriť parameter kategórie')
			}
		]
	}

	return (
		<>
			<Row>
				<Breadcrumbs breadcrumbs={breadcrumbs} />
			</Row>
			<div className='content-body small'>
				<CategoryParamsForm onSubmit={handleSubmit} />
			</div>
		</>
	)
}

export default compose(withPermissions([PERMISSION.CATEGORY_PARAMETER_EDIT]))(CreateCategoryParamsPage)
