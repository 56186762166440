import React, { FC } from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { Button, Form } from 'antd'
import { Link } from 'react-router'
import { useTranslation } from 'react-i18next'

// assets
import InfoIcon from '../../../assets/icons/info-icon.svg?react'

// atoms
import InputField from '../../../atoms/InputField'
import InputPasswordField from '../../../atoms/InputPasswordField'
import SwitchField from '../../../atoms/SwitchField'

// components
import PhoneWithPrefixField from '../../../components/PhoneWithPrefixField'
import ReCaptchaTermsAndConditions from '../../../components/ReCaptchaTermsAndConditions'

// utils
import { ASSET_TYPE, FORM, SET_OF_COUNTRIES, SUBMIT_BUTTON_ID } from '../../../utils/enums'
import { formFieldID, showErrorNotification } from '../../../utils/helper'

// types
import { IDocumentsPayload } from '../../../reducers/documents/documentActions'
import { ILoadingAndFailure, IRegistrationForm } from '../../../types/interfaces'

// validation
import { validationRegistrationFn } from '../../../utils/formValidation'

type ComponentProps = {
	documents: IDocumentsPayload & ILoadingAndFailure
	isSubmitting: boolean
}

type Props = InjectedFormProps<IRegistrationForm, ComponentProps> & ComponentProps

const getDocumentsLinks = (documents?: NonNullable<IDocumentsPayload['data']>['documents']) => {
	let termsOfConditionLink
	let privacyPolicyLink

	documents?.forEach((document) => {
		if (document.assetType.key === ASSET_TYPE.B2B_APP_TERMS_CONDITIONS) {
			termsOfConditionLink = document.files[0].original
		} else if (document.assetType.key === ASSET_TYPE.B2B_PRIVACY_POLICY) {
			privacyPolicyLink = document.files[0].original
		}
	})

	return { termsOfConditionLink, privacyPolicyLink }
}

const RegistrationForm: FC<Props> = (props) => {
	const [t] = useTranslation()
	const { handleSubmit, isSubmitting, documents } = props

	const { termsOfConditionLink, privacyPolicyLink } = getDocumentsLinks(documents.data?.documents)

	return (
		<Form layout={'vertical'} className={'form registration-form max-w-80 flex flex-col mx-auto'} onSubmitCapture={handleSubmit}>
			<h3 className='mb-0'>{t('loc:Registrácia')}</h3>
			<Field component={InputField} label={t('loc:Email')} placeholder={t('loc:Zadajte email')} name={'email'} size={'large'} required />
			<Field
				component={InputPasswordField}
				label={t('loc:Heslo')}
				placeholder={t('loc:Zadajte heslo')}
				type={'password'}
				size={'large'}
				name={'password'}
				required
				tooltip={{ title: t('loc:Aspoň 8 znakov, 1 číslo, 1 veľký, 1 malý a 1 špeciálny znak'), icon: <InfoIcon width={14} height={14} /> }}
			/>
			<PhoneWithPrefixField
				label={t('loc:Telefón')}
				placeholder={t('loc:Zadajte telefón')}
				size={'large'}
				prefixName={'phonePrefixCountryCode'}
				phoneName={'phone'}
				formName={FORM.REGISTRATION}
				countries={SET_OF_COUNTRIES.ROLLOUT}
				required
			/>
			<Field
				className='noti-registration-switch'
				component={SwitchField}
				name={'agreeGDPR'}
				customLabel={
					<div className='text-notino-grayDark text-xs'>
						<span>{`${t('loc:Prehlasujem, že som sa oboznámil s')} `}</span>
						<a
							onClick={(e) => {
								e.stopPropagation()
								if (!termsOfConditionLink || documents.isFailure || documents.isLoading) {
									e.preventDefault()
								}
							}}
							className='text-notino-grayDark hover:text-notino-pink'
							href={termsOfConditionLink}
							target='_blank'
							rel='noreferrer'
						>
							<u>{t('loc:Podmienkami používania')}</u>
						</a>
						{` ${t('loc:a')} `}
						<a
							onClick={(e) => {
								e.stopPropagation()
								if (!termsOfConditionLink || documents.isFailure || documents.isLoading) {
									e.preventDefault()
								}
							}}
							className='text-notino-grayDark hover:text-notino-pink'
							href={privacyPolicyLink}
							target='_blank'
							rel='noreferrer'
						>
							<u>{t('loc:Zásadami spracovania osobných údajov')}</u>
						</a>
						<span>{` ${t('loc:a chcem sa zaregistrovať do programu Notino Partner.')}`}</span>
					</div>
				}
				size={'large'}
			/>
			<Field
				className='noti-registration-switch marketing-field'
				component={SwitchField}
				name={'marketing'}
				customLabel={
					<div className='text-notino-grayDark text-xs w-11/12'>{t('loc:V súvislosti s touto registráciou si neprajem dostávať informácie o novinkách a akciách.')}</div>
				}
				size={'large'}
				tooltipText={
					<div>
						{`${t(
							'loc:Spoločnosť Notino, s.r.o. je oprávnená kontaktovať registrovaných užívateľov z dôvodu priameho marketingu. Aby sme zamedzili nevyžiadanej pošte, potvrďte, ak toto spracovanie namietate. Viac informácii v'
						)} `}
						<a onClick={(e) => e.stopPropagation()} className='text-notino-pink font-semibold' href={privacyPolicyLink} target='_blank' rel='noreferrer'>
							{t('loc:zásadách spracovania osobných údajov.')}
						</a>
					</div>
				}
			/>

			<Button
				id={formFieldID(FORM.REGISTRATION, SUBMIT_BUTTON_ID)}
				type={'primary'}
				block
				className={`noti-btn m-regular mb-2`}
				htmlType={'submit'}
				disabled={isSubmitting}
				loading={isSubmitting}
			>
				{t('loc:Registrovať')}
			</Button>
			<ReCaptchaTermsAndConditions className={'text-center mb-1 md:mb-2'} />
			<span className='flex items-center md:justify-center text-notino-black font-medium'>
				{`${t('loc:Už ste registrovaný?')} `}
				<Link to={`${t('paths:login')}`}>
					<Button className='p-0 ml-1 font-medium' type={'link'} htmlType={'button'}>
						{t('loc:Prihlásiť sa')}
					</Button>
				</Link>
			</span>
		</Form>
	)
}

const form = reduxForm<IRegistrationForm, ComponentProps>({
	form: FORM.REGISTRATION,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	onSubmitFail: showErrorNotification,
	validate: validationRegistrationFn
})(RegistrationForm)

export default form
