import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Row, Spin } from 'antd'
import { initialize, submit } from 'redux-form'
import cx from 'classnames'
import { useNavigate } from 'react-router'

// components
import { scrollToTopFn } from '../../components/ScrollToTop'
import SpecialistModal from './components/modals/SpecialistModal'
import ReCaptchaTermsAndConditions from '../../components/ReCaptchaTermsAndConditions'
import SalonForm from './components/forms/SalonForm'

// enums
import { FORM, GRECAPTCHA_ACTIONS, NOTI_SPECIALIST_BUTTON_ID, PERMISSION, STRINGS, SUBMIT_BUTTON_ID } from '../../utils/enums'

// reducers
import { getCurrentUser } from '../../reducers/users/userActions'

// types
import { ISalonForm, SalonPageProps } from '../../types/interfaces'

// utils
import { postReq } from '../../utils/request'
import Permissions from '../../utils/Permissions'
import { formFieldID } from '../../utils/helper'
import reCaptchaVerify from '../../utils/reCaptcha'
import { getSalonDataForSubmission, initEmptySalonFormData } from './components/salonUtils'

// assets
import SpecialistIcon from '../../assets/icons/specialist-icon.svg?react'
import CreateIcon from '../../assets/icons/plus-icon.svg?react'

const permissions: PERMISSION[] = [PERMISSION.NOTINO, PERMISSION.PARTNER]

const CreateSalonPage: FC<SalonPageProps> = (props) => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { phonePrefixCountryCode, authUser, configLoading, isNotinoUser } = props

	const [submitting, setSubmitting] = useState<boolean>(false)
	const [specialistModalVisible, setSpecialistModalVisible] = useState(false)

	const isLoading = configLoading || authUser?.isLoading

	// init form on mount
	useEffect(() => {
		dispatch(initialize(FORM.SALON, initEmptySalonFormData(phonePrefixCountryCode)))
	}, [dispatch, phonePrefixCountryCode])

	const handleSubmit = async (data: ISalonForm) => {
		setSubmitting(true)

		try {
			const reCaptchaToken = await reCaptchaVerify(GRECAPTCHA_ACTIONS.CREATE_SALON_FORM)

			const result = await postReq('/api/b2b/admin/salons/', {
				params: {},
				reqBody: getSalonDataForSubmission(data, isNotinoUser),
				customConfig: { headers: { 'recaptcha-token': reCaptchaToken } }
			})

			if (result?.data?.salon?.id) {
				// load new salon for current user
				await dispatch(getCurrentUser())
				// select new salon
				navigate(t('paths:salons/{{salonID}}', { salonID: result.data.salon.id }))
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		} finally {
			setSubmitting(false)
			scrollToTopFn()
		}
	}

	return (
		<>
			<div className='content-body'>
				<Spin spinning={isLoading}>
					<SalonForm onSubmit={handleSubmit} />
					<div className={'content-footer'}>
						<Row className={'w-full'} justify={'space-between'}>
							<div className={'max-w-96'}>
								<ReCaptchaTermsAndConditions />
							</div>
							<Permissions
								allowed={permissions}
								render={(hasPermission, { openForbiddenModal }) => (
									<Button
										id={formFieldID(FORM.SALON, SUBMIT_BUTTON_ID)}
										type={'primary'}
										size={'middle'}
										className={'noti-btn m-regular w-full md:w-auto md:min-w-50 xl:min-w-60'}
										htmlType={'submit'}
										icon={<CreateIcon />}
										onClick={(e) => {
											if (hasPermission) {
												dispatch(submit(FORM.SALON))
											} else {
												e.preventDefault()
												openForbiddenModal()
											}
										}}
										disabled={submitting}
										loading={submitting}
									>
										{STRINGS(t).createRecord(t('loc:salón'))}
									</Button>
								)}
							/>
						</Row>
					</div>
				</Spin>
			</div>
			{specialistModalVisible && <SpecialistModal visible onCancel={() => setSpecialistModalVisible(false)} />}
			<button
				id={NOTI_SPECIALIST_BUTTON_ID}
				type={'button'}
				className={cx('noti-specialist-button', { 'is-active': specialistModalVisible })}
				onClick={() => setSpecialistModalVisible(true)}
			>
				<SpecialistIcon />
				<span>{t('loc:Notino Špecialista')}</span>
			</button>
		</>
	)
}

export default CreateSalonPage
