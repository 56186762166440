import React from 'react'
import { Navigate, RouteProps } from 'react-router'
import { useTranslation } from 'react-i18next'

import BaseRoute from './BaseRoute'
import { isLoggedIn } from '../utils/auth'
import LogoutUser from '../utils/LogoutUser'
import { GA_SCREEN_NAME } from '../utils/dataLayerEnums'

export type Props = RouteProps & {
	layout?: React.ComponentType<any>
	className?: string
	showBackButton?: boolean
	showLegacyDocumentsLinks?: boolean
	logoutUser?: boolean
	skipRedirectToLoginPage?: boolean
	gaEvents?: {
		changeLanguage?: {
			screenName: GA_SCREEN_NAME
		}
	}
}

const PublicRoute = (props: Props) => {
	const [t] = useTranslation()

	const { skipRedirectToLoginPage = false } = props

	if (!skipRedirectToLoginPage && isLoggedIn()) {
		return <Navigate to={t('paths:index')} />
	}

	return <BaseRoute {...props} />
}

const PublicRouteWrapper = (props: Props) => {
	return (
		<LogoutUser skipRedirectToLoginPage={props.skipRedirectToLoginPage}>
			<PublicRoute {...props} />
		</LogoutUser>
	)
}

export default PublicRouteWrapper
