import React, { useRef, useEffect, useState } from 'react'
import { Layout, Menu, Dropdown, Row, MenuProps, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useLocation } from 'react-router'
import cx from 'classnames'
import { sortBy } from 'lodash'
import i18next from 'i18next'

// assets
import LogoIcon from '../../assets/images/logo-simple.svg?react'
import NotinoPartnerIcon from '../../assets/icons/notino-partner-icon.svg?react'
import HomeIcon from '../../assets/icons/home-icon-filled.svg?react'
import CategoryIcon from '../../assets/icons/categories-icon.svg?react'
import SalonIcon from '../../assets/icons/salon-icon.svg?react'
import ServiceIcon from '../../assets/icons/service-icon.svg?react'
import UsersIcon from '../../assets/icons/users-icon.svg?react'
import CustomerIcon from '../../assets/icons/customer-icon.svg?react'
import SpecialistIcon from '../../assets/icons/specialist-icon.svg?react'
import ProfileIcon from '../../assets/icons/profile-icon.svg?react'
import LogOutIcon from '../../assets/icons/logout-icon.svg?react'
import ChevronIcon from '../../assets/icons/up-down-icon.svg?react'
import VersionIcon from '../../assets/icons/version-icon.svg?react'
import EmployeesIcon from '../../assets/icons/employees-icon.svg?react'
import HelpIcon from '../../assets/icons/help-icon.svg?react'
import CosmeticIcon from '../../assets/icons/cosmetic-icon.svg?react'
import LanguagesIcon from '../../assets/icons/languages-icon.svg?react'
import ParametersIcon from '../../assets/icons/parameters-icon.svg?react'
import IndustriesIcon from '../../assets/icons/industries-icon.svg?react'
import InvoiceIcon from '../../assets/icons/invoice-icon.svg?react'
import ChevronRightIcon from '../../assets/icons/chevron-right.svg?react'
import CalendarIcon from '../../assets/icons/calendar-icon.svg?react'
import SettingIcon from '../../assets/icons/setting-icon.svg?react'
import ReservationsIcon from '../../assets/icons/reservations-icon.svg?react'
import ReviewsIcon from '../../assets/icons/reviews-icon.svg?react'
import CoinsIcon from '../../assets/icons/coins-icon.svg?react'
import SmsUnitPricesIcon from '../../assets/icons/sms-unit-prices-icon.svg?react'
import DocumentsIcon from '../../assets/icons/document-icon.svg?react'
import InfoIcon from '../../assets/icons/info-icon.svg?react'
import MyDocumentsIcon from '../../assets/icons/documents-icon.svg?react'
import BellIcon from '../../assets/icons/bell-icon.svg?react'
import CameraIcon from '../../assets/icons/camera-icon.svg?react'
import ThumbsUpIcon from '../../assets/icons/thumbs-up-icon.svg?react'
import BannerIcon from '../../assets/icons/regular-image-icon.svg?react'

// utils
import { ADMIN_PERMISSIONS, CYPRESS_CLASS_NAMES, PAGE, PERMISSION } from '../../utils/enums'
import { checkPermissions } from '../../utils/Permissions'
import { pushEventToDataLayer } from '../../utils/dataLayer'
import { DATA_LAYER_EVENTS, GA_CONTEXT_MENU_TYPE, GA_SCREEN_NAME } from '../../utils/dataLayerEnums'

// redux
import { logOutUser } from '../../reducers/users/userActions'
import { RootState } from '../../reducers'
import { getSupportContact } from '../../reducers/supportContacts/supportContactsActions'
import { setIsSiderCollapsed } from '../../reducers/helperSettings/helperSettingsActions'

// components
import UserAvatar from '../AvatarComponents'
import NewBadge from '../NewBadge'

// env
import envConfig from '../../config'

const { Sider } = Layout

export type LayoutSiderProps = {
	salonID?: string
	parentPath?: string
	initialSalonLoading: boolean
}

const SIDER_ID = 'noti-sider-wrapper'
const SIDER_TRIGGER_HEIGHT = 48
const LOGO_HEIGHT = 72

const MENU_ITEMS_ORDER = [
	// Notino view
	PAGE.HOME,
	'divider-1',
	PAGE.USERS,
	PAGE.CATEGORIES,
	PAGE.CATEGORY_PARAMETERS,
	PAGE.COSMETICS,
	PAGE.LANGUAGES,
	PAGE.SUPPORT_CONTACTS,
	PAGE.SPECIALIST_CONTACTS,
	PAGE.REVIEWS,
	PAGE.SMS_CREDITS,
	PAGE.NOTINO_RESERVATIONS,
	PAGE.DOCUMENTS,
	PAGE.DYNAMIC_NOTIFICATIONS,
	PAGE.SALONS,
	PAGE.BANNERS,
	// Salon view
	PAGE.HOME_PARTNER,
	'divider-2',
	PAGE.SALON_DETAIL,
	PAGE.BILLING_INFO,
	PAGE.INDUSTRIES_AND_SERVICES,
	PAGE.SERVICES_SETTINGS,
	PAGE.PHOTOALBUMS,
	PAGE.EMPLOYEES,
	PAGE.SALON_SETTINGS,
	PAGE.SALON_REVIEWS,
	PAGE.SMS_CREDIT,
	PAGE.AFFILIATE_PROGRAM,
	'divider-3',
	PAGE.CUSTOMERS,
	PAGE.RESERVATIONS,
	PAGE.CALENDAR
]

type MyAccountMenuSelectedOption = 'personal_data' | 'settings' | 'documents' | 'need_help' | 'about_application' | 'log_out'

const pushGaSelectContextMenuOptionEvent = (selectedOption: MyAccountMenuSelectedOption, screenName: GA_SCREEN_NAME | null) => {
	if (!screenName) {
		return
	}

	pushEventToDataLayer({
		event: DATA_LAYER_EVENTS.SELECT_CONTEXT_MENU_OPTION,
		context_menu_type: GA_CONTEXT_MENU_TYPE.ACCOUNT_MENU,
		screen_name: screenName,
		selected_option: selectedOption
	})
}

type MenuItemProps = {
	path: string
	label: React.ReactNode
	page: PAGE
	icon: React.ReactNode
	collapsed: boolean
	onClick?: () => void
	hasBadge?: boolean
	routerPathname: string
	buttonName?: string
}

const getMenuItem = (props: MenuItemProps) => {
	const { path, label, page, icon, onClick, hasBadge, routerPathname, buttonName, collapsed } = props

	// NOTE: The salon detail path is "/salons/{salonID}".
	// All other salon paths are subpaths of the salon detail path, for example "/salons/{salonID}/employees".
	// When the user visits any subpath, <NavLink> matches as active both the currently selected subpath and the salon detail path.
	// This workaround ensures that the salon detail path is marked as active only when the user is on the salon detail page, not on a subpath.
	const splittedPath = routerPathname.split('/').filter((item) => item)
	const isSalonDetailPath = splittedPath.length === 2 && `/${splittedPath[0]}` === i18next.t('paths:salons')

	// NOTE: /reservations-dashboard route is part of Homepage - so the Homepage menu item should be selected
	const isReservationDashboardPath = routerPathname === i18next.t('paths:reservations-dashboard')
	const navLink = (
		<NavLink
			to={path}
			onClick={() => {
				if (buttonName) {
					pushEventToDataLayer({ event: DATA_LAYER_EVENTS.CLICK_BUTTON, button_name: buttonName })
				}
			}}
			className={({ isActive }) => {
				let active = isActive

				if (!isSalonDetailPath) {
					active = isActive && page !== PAGE.SALON_DETAIL
				}

				if (isReservationDashboardPath && page === PAGE.HOME) {
					active = true
				}

				if (active) {
					return 'noti-menu-item noti-menu-item-selected'
				}
				return 'noti-menu-item'
			}}
		>
			{icon}
			<span className={'noti-menu-item-label'}>{label}</span>
			{hasBadge && <NewBadge size={'small'} pageEnum={page} className={'ml-4'} />}
		</NavLink>
	)

	const item = {
		key: page,
		className: 'noti-menu-item-custom',
		label: (
			<Tooltip placement={collapsed ? 'topLeft' : 'right'} mouseEnterDelay={0.5} title={label} destroyTooltipOnHide>
				{navLink}
			</Tooltip>
		),
		id: page,
		onClick
	}

	return item
}

const LayoutSider = (props: LayoutSiderProps) => {
	const { salonID, parentPath, initialSalonLoading } = props

	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useLocation()

	const collapsed = useSelector((state: RootState) => state.helperSettings.isSiderCollapsed)
	const currentUser = useSelector((state: RootState) => state.user.authUser.data)
	const selectedSalon = useSelector((state: RootState) => state.selectedSalon.selectedSalon.data)
	const pendingReservationsCount = useSelector((state: RootState) => state.paginatedReservations.pendingReservationsCount.count)
	const googleAnalyticsScreenName = useSelector((state: RootState) => state.googleAnalytics.screenName)
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)

	const isUserActivated = !!currentUser?.activateAt
	const authUserPermissions = currentUser?.uniqPermissions
	const count = pendingReservationsCount > 0 ? `(${pendingReservationsCount})` : ''
	const hasUnreadDocuments = (currentUser?.countUnreadLastVersionDocuments || 0) > 0
	const routerPathname = location.pathname

	const hasPermissions = (allowed: PERMISSION[] = [], except: PERMISSION[] = []) => {
		const rolePermissions = authUserPermissions || []
		const salonPermission = selectedSalon?.uniqPermissions || []
		return checkPermissions([...rolePermissions, ...salonPermission], allowed, except)
	}

	const getPath = (pathSuffix: string) => `${parentPath}${pathSuffix}`

	const getMainMenuItems = () => {
		const mainGroupItems: any[] = []

		if (!isUserActivated) {
			return mainGroupItems
		}

		if (!salonID) {
			// ADMIN VIEW
			if (hasPermissions([PERMISSION.NOTINO])) {
				mainGroupItems.push(
					getMenuItem({
						page: PAGE.HOME,
						path: t('paths:index'),
						label: t('loc:Prehľad'),
						icon: <HomeIcon />,
						routerPathname,
						buttonName: 'overview',
						collapsed
					}),
					{
						type: 'divider',
						key: 'divider-1',
						className: 'my-1 border-gray-200'
					}
				)
			}

			if (hasPermissions([PERMISSION.USER_BROWSING])) {
				mainGroupItems.push(
					getMenuItem({
						page: PAGE.USERS,
						path: t('paths:users'),
						label: t('loc:Používatelia'),
						icon: <UsersIcon className={'text-notino-black'} />,
						routerPathname,
						collapsed
					})
				)
			}
			if (hasPermissions([PERMISSION.ENUM_EDIT])) {
				mainGroupItems.push(
					getMenuItem({
						page: PAGE.CATEGORIES,
						path: t('paths:categories'),
						label: t('loc:Kategórie'),
						icon: <CategoryIcon className={'text-notino-black'} />,
						routerPathname,
						collapsed
					}),
					getMenuItem({
						page: PAGE.CATEGORY_PARAMETERS,
						path: t('paths:category-parameters'),
						label: t('loc:Parametre'),
						icon: <ParametersIcon className={'text-notino-black'} />,
						routerPathname,
						collapsed
					}),
					getMenuItem({
						page: PAGE.COSMETICS,
						path: t('paths:cosmetics'),
						label: t('loc:Kozmetika'),
						icon: <CosmeticIcon className={'text-notino-black'} />,
						routerPathname,
						collapsed
					}),
					getMenuItem({
						page: PAGE.LANGUAGES,
						path: t('paths:languages-in-salons'),
						label: t('loc:Jazyky'),
						icon: <LanguagesIcon className={'text-notino-black'} />,
						routerPathname,
						collapsed
					}),
					getMenuItem({
						page: PAGE.SUPPORT_CONTACTS,
						path: t('paths:support-contacts'),
						label: t('loc:Podpora'),
						icon: <HelpIcon className={'text-notino-black'} />,
						routerPathname,
						collapsed
					}),
					getMenuItem({
						page: PAGE.SPECIALIST_CONTACTS,
						path: t('paths:specialist-contacts'),
						label: t('loc:Špecialisti'),
						icon: <SpecialistIcon className={'text-notino-black'} />,
						routerPathname,
						collapsed
					}),
					getMenuItem({
						page: PAGE.REVIEWS,
						path: t('paths:reviews'),
						label: t('loc:Recenzie'),
						icon: <ReviewsIcon className={'text-notino-black'} />,
						routerPathname,
						collapsed
					})
				)
			}
			if (hasPermissions([PERMISSION.SMS_UNIT_PRICE_EDIT, PERMISSION.NOTINO])) {
				mainGroupItems.push(
					getMenuItem({
						page: PAGE.SMS_CREDITS,
						path: t('paths:sms-credits'),
						label: t('loc:SMS kredity'),
						icon: <SmsUnitPricesIcon className={'text-notino-black'} />,
						routerPathname,
						collapsed
					})
				)
			}

			if (hasPermissions(ADMIN_PERMISSIONS)) {
				mainGroupItems.push(
					getMenuItem({
						page: PAGE.DOCUMENTS,
						path: t('paths:documents'),
						label: t('loc:Dokumenty'),
						icon: <DocumentsIcon className={'text-black'} />,
						routerPathname,
						collapsed
					})
				)
			}

			if (hasPermissions([PERMISSION.DYNAMIC_NOTIFICATION_BROWSING, PERMISSION.DYNAMIC_NOTIFICATION_CREATE])) {
				mainGroupItems.push(
					getMenuItem({
						page: PAGE.DYNAMIC_NOTIFICATIONS,
						path: t('paths:notifications'),
						label: t('loc:Upozornenia'),
						icon: <BellIcon className={'text-notino-black'} />,
						routerPathname,
						collapsed
					})
				)
			}

			if (hasPermissions([PERMISSION.CMS_BANNER_READ])) {
				mainGroupItems.push(
					getMenuItem({
						page: PAGE.BANNERS,
						path: t('paths:banners'),
						label: t('loc:Bannery'),
						icon: <BannerIcon className={'text-notino-black'} />,
						routerPathname,
						collapsed
					})
				)
			}

			if (hasPermissions([PERMISSION.NOTINO])) {
				mainGroupItems.push(
					getMenuItem({
						page: PAGE.NOTINO_RESERVATIONS,
						path: t('paths:reservations'),
						label: t('loc:Prehľad rezervácii'),
						icon: <ReservationsIcon className={'text-notino-black'} />,
						routerPathname,
						collapsed
					}),
					getMenuItem({
						page: PAGE.SALONS,
						path: t('paths:salons'),
						label: t('loc:Salóny'),
						icon: <SalonIcon className={'text-notino-black'} />,
						routerPathname,
						collapsed
					})
				)
			}
		}

		if (salonID) {
			// SALON VIEW
			mainGroupItems.push(
				getMenuItem({
					page: PAGE.HOME_PARTNER,
					path: getPath(t('paths:dashboard')),
					label: t('loc:Prehľad'),
					icon: <HomeIcon />,
					routerPathname,
					buttonName: 'overview',
					collapsed
				}),
				{
					type: 'divider',
					key: 'divider-2',
					className: 'my-1 border-gray-200'
				}
			)

			if (hasPermissions([PERMISSION.NOTINO, PERMISSION.PARTNER])) {
				mainGroupItems.push(
					getMenuItem({
						page: PAGE.SALON_DETAIL,
						path: parentPath ?? '',
						label: t('loc:Detail salónu'),
						icon: <SalonIcon className={'text-notino-black'} />,
						routerPathname,
						buttonName: 'salon_detail',
						collapsed
					}),
					getMenuItem({
						page: PAGE.BILLING_INFO,
						path: getPath(t('paths:billing-info')),
						label: t('loc:Fakturačné údaje'),
						icon: <InvoiceIcon className={'text-notino-black'} />,
						routerPathname,
						buttonName: 'billing_information',
						collapsed
					}),
					getMenuItem({
						page: PAGE.SERVICES_SETTINGS,
						path: getPath(t('paths:services-settings')),
						label: t('loc:Nastavenie služieb'),
						icon: <ServiceIcon className={'text-black medium-icon'} strokeWidth={'0.5'} />,
						routerPathname,
						buttonName: 'services_settings',
						collapsed
					}),
					getMenuItem({
						page: PAGE.EMPLOYEES,
						path: getPath(t('paths:employees')),
						label: t('loc:Zamestnanci'),
						icon: <EmployeesIcon className={'text-notino-black'} />,
						routerPathname,
						buttonName: 'colleagues',
						collapsed
					}),
					getMenuItem({
						page: PAGE.SALON_SETTINGS,
						path: getPath(t('paths:settings')),
						label: t('loc:Nastavenia'),
						icon: <SettingIcon className={'text-notino-black'} />,
						routerPathname,
						buttonName: 'settings',
						collapsed
					}),
					getMenuItem({
						page: PAGE.INDUSTRIES_AND_SERVICES,
						path: getPath(t('paths:industries-and-services')),
						label: t('loc:Odvetvia a služby'),
						icon: <IndustriesIcon className={'text-notino-black'} />,
						routerPathname,
						buttonName: 'industry_and_services',
						collapsed
					}),
					getMenuItem({
						page: PAGE.PHOTOALBUMS,
						path: getPath(t('paths:photoalbums')),
						label: t('loc:Fotoalbumy'),
						icon: <CameraIcon className={'text-notino-black'} />,
						routerPathname,
						collapsed
					}),
					{
						type: 'divider',
						key: 'divider-3',
						className: 'my-1 border-gray-200'
					},
					getMenuItem({
						page: PAGE.CUSTOMERS,
						path: getPath(t('paths:customers')),
						label: t('loc:Zákazníci'),
						icon: <CustomerIcon className={'text-black'} />,
						routerPathname,
						buttonName: 'clients',
						collapsed
					}),
					getMenuItem({
						page: PAGE.RESERVATIONS,
						path: getPath(t('paths:salon-reservations')),
						label: t('loc:Prehľad rezervácií {{ reservationsCount }}', { reservationsCount: count }),
						icon: <ReservationsIcon className={'text-notino-black'} />,
						routerPathname,
						buttonName: 'list_of_reservations',
						collapsed
					}),
					getMenuItem({
						page: PAGE.CALENDAR,
						path: getPath(t('paths:calendar')),
						label: t('loc:Plánovací kalendár'),
						icon: <CalendarIcon className={'text-notino-black'} />,
						routerPathname,
						buttonName: 'planning_calendar',
						collapsed
					})
				)
			}
			if (hasPermissions([PERMISSION.NOTINO, PERMISSION.SALON_REVIEW_READ, PERMISSION.PARTNER_ADMIN])) {
				mainGroupItems.push(
					getMenuItem({
						page: PAGE.SALON_REVIEWS,
						path: getPath(t('paths:reviews')),
						label: t('loc:Recenzie'),
						icon: <ThumbsUpIcon className={'text-notino-black'} />,
						routerPathname,
						buttonName: 'reviews',
						collapsed
					})
				)
			}
			if (hasPermissions([PERMISSION.NOTINO, PERMISSION.PARTNER_ADMIN, PERMISSION.READ_WALLET])) {
				mainGroupItems.push(
					getMenuItem({
						page: PAGE.SMS_CREDIT,
						path: getPath(t('paths:sms-credit')),
						label: t('loc:SMS kredit'),
						icon: <SmsUnitPricesIcon className={'text-notino-black'} />,
						routerPathname,
						buttonName: 'sms_credit',
						collapsed
					})
				)
			}

			if (hasPermissions([PERMISSION.NOTINO, PERMISSION.PARTNER_ADMIN, PERMISSION.READ_WALLET, PERMISSION.CREATE_TRANSACTION_AFFILIATE_WALLET_TO_SMS_WALLET])) {
				mainGroupItems.push(
					getMenuItem({
						page: PAGE.AFFILIATE_PROGRAM,
						path: getPath(t('paths:loyalty-program')),
						label: t('loc:Vernostný program'),
						icon: <CoinsIcon className={'text-black'} />,
						routerPathname,
						buttonName: 'affiliate_program',
						collapsed
					})
				)
			}
		}
		return sortBy(mainGroupItems, (item) => MENU_ITEMS_ORDER.indexOf(item.key))
	}

	const getMyAccountMenuItems = () => {
		const myAccountMenuItems: MenuProps['items'] = [
			{
				key: 'myProfile',
				className: CYPRESS_CLASS_NAMES.MY_ACCOUNT_PERSONAL_DATA,
				label: <Link to={t('paths:my-account')}> {t('loc:Osobné údaje')}</Link>,
				icon: <ProfileIcon />,
				onClick: () => pushGaSelectContextMenuOptionEvent('personal_data', googleAnalyticsScreenName)
			},
			{
				key: 'accountSettings',
				label: <Link to={t('paths:account-settings')}> {t('loc:Nastavenia')}</Link>,
				icon: <SettingIcon />,
				onClick: () => pushGaSelectContextMenuOptionEvent('settings', googleAnalyticsScreenName)
			},
			{
				key: 'myDocuments',
				className: CYPRESS_CLASS_NAMES.MY_ACCOUNT_DOCUMENTS,
				label: (
					<Link to={t('paths:my-documents')}>
						<span className={'ant-menu-title-content block leading-3'}>{t('loc:Dokumenty')}</span>
						{hasUnreadDocuments && <NewBadge size={'small'} label={t('loc:Aktualizované')} className={CYPRESS_CLASS_NAMES.MY_ACCOUNT_NEW_DOCUMENT_BADGE} />}
					</Link>
				),
				icon: <MyDocumentsIcon width={24} height={24} />,
				onClick: () => pushGaSelectContextMenuOptionEvent('documents', googleAnalyticsScreenName)
			},
			{
				key: 'support',
				label: (
					<Link to={t('paths:contact')} state={{ from: location.pathname + location.search }}>
						{t('loc:Potrebujete pomôcť?')}
					</Link>
				),
				onClick: () => {
					// reset support contact data to empty in case there are some stored in redux
					// otherwise language detection would not work correctly in t('paths:contact') page
					dispatch(getSupportContact())

					pushGaSelectContextMenuOptionEvent('need_help', googleAnalyticsScreenName)
				},
				icon: <HelpIcon />
			},
			{
				key: 'about-app',
				label: <Link to={t('paths:about-application')}>{t('loc:O aplikácii')}</Link>,
				icon: <InfoIcon width={24} height={24} />,
				onClick: () => pushGaSelectContextMenuOptionEvent('about_application', googleAnalyticsScreenName)
			},
			{
				key: 'logOut',
				className: CYPRESS_CLASS_NAMES.LOGOUT_BUTTON,
				label: t('loc:Odhlásiť'),
				onClick: () => {
					pushGaSelectContextMenuOptionEvent('log_out', googleAnalyticsScreenName)
					dispatch(logOutUser())
				},
				icon: <LogOutIcon />
			},
			{
				type: 'divider',
				key: 'divider1',
				className: 'my-1 border-gray-200'
			},
			{
				key: 'version',
				className: 'cursor-text',
				icon: <VersionIcon />,
				disabled: true,
				label: <span className='s-medium'>v{envConfig.APP_VERSION}</span>
			}
		]

		return myAccountMenuItems
	}

	const wasSiderRendered = useRef(false)

	useEffect(() => {
		wasSiderRendered.current = true
	}, [])

	return (
		<Sider
			className={cx('bg-white shadow-md z-50 main-layout-sider', { 'account-dropdown-opened': isDropdownOpen })}
			breakpoint='md'
			collapsedWidth={56}
			width={230}
			trigger={<ChevronRightIcon style={{ transform: !collapsed ? 'rotate(180deg)' : undefined, width: 12, height: 12, color: '#000' }} />}
			collapsible
			collapsed={collapsed}
			onCollapse={(isCollapsed, type) => {
				if (type === 'clickTrigger') {
					dispatch(setIsSiderCollapsed(isCollapsed))
				} else if (type === 'responsive' && wasSiderRendered.current) {
					dispatch(setIsSiderCollapsed(isCollapsed))
				}
			}}
		>
			<div id={SIDER_ID} className='flex flex-col h-full'>
				{collapsed ? (
					<Link className='flex justify-center pt-4 pb-6' to={`${t('paths:index')}`} style={{ height: LOGO_HEIGHT }}>
						<NotinoPartnerIcon className='h-8' />
					</Link>
				) : (
					<Link className='flex justify-center items-center' to={`${t('paths:index')}`} style={{ height: LOGO_HEIGHT }}>
						<LogoIcon style={{ padding: 10 }} />
					</Link>
				)}
				{initialSalonLoading ? null : (
					<Menu
						mode='inline'
						className='px-2 flex flex-col flex-grow noti-sider-menu'
						style={{ height: `calc(100% - ${LOGO_HEIGHT}px` }}
						inlineIndent={8}
						items={[
							{
								key: 'main-group',
								type: 'group',
								className: 'noti-sider-main-group h-full flex flex-col justify-between',
								children: [
									{
										key: 'group-menu-items',
										type: 'group',
										className: 'overflow-y-auto',
										style: { height: `calc(100% - ${SIDER_TRIGGER_HEIGHT}px` },
										children: getMainMenuItems()
									},
									{
										key: 'user-account',
										className: 'noti-account-menu-item',
										label: (
											<Dropdown
												menu={{
													className: 'noti-sider-menu noti-account-dropdown',
													getPopupContainer: () => document.querySelector(`#${SIDER_ID}`) as HTMLElement,
													items: getMyAccountMenuItems()
												}}
												placement={'topLeft'}
												trigger={['click']}
												overlayStyle={{ minWidth: 214 }}
												align={
													collapsed
														? {
																offset: [54, 40]
															}
														: undefined
												}
												getPopupContainer={() => document.querySelector(`#${SIDER_ID}`) as HTMLElement}
												onOpenChange={setIsDropdownOpen}
											>
												<button
													type={'button'}
													className='cursor-pointer w-full'
													onClick={(e) => {
														e.preventDefault()

														// push GA data only when dropdown is going to be open
														if (googleAnalyticsScreenName && !isDropdownOpen) {
															pushEventToDataLayer({
																event: DATA_LAYER_EVENTS.OPEN_CONTEXT_MENU,
																context_menu_type: GA_CONTEXT_MENU_TYPE.ACCOUNT_MENU,
																screen_name: googleAnalyticsScreenName
															})
														}
													}}
													onKeyPress={(e) => e.preventDefault()}
												>
													<Row className='flex items-center' justify='space-between'>
														<Row className={CYPRESS_CLASS_NAMES.MY_ACCOUNT}>
															<div className='truncate item-label flex items-center'>{t('loc:Moje konto')}</div>
														</Row>

														<ChevronIcon className='items-center' />
													</Row>
												</button>
											</Dropdown>
										),
										icon: (
											<div className={'relative'}>
												{hasUnreadDocuments && <span className={'absolute w-1 h-1 rounded-full bg-notino-pink z-10 top-0 right-0'} />}
												<UserAvatar
													src={currentUser?.image?.original}
													text={`${currentUser?.firstName?.[0]}${currentUser?.lastName?.[0]}`}
													className={'shrink-0 grow-0'}
												/>
											</div>
										)
									}
								]
							}
						]}
						selectedKeys={[]}
						getPopupContainer={() => document.querySelector(`#${SIDER_ID}`) as HTMLElement}
					/>
				)}
			</div>
		</Sider>
	)
}
export default LayoutSider
