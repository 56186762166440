import React from 'react'
import qs from 'qs'
import { jwtDecode as decode } from 'jwt-decode'
import { get } from 'lodash'
import { Navigate, RouteProps } from 'react-router'
import i18next from 'i18next'

// routes
import BaseRoute from './BaseRoute'

// utils
import { TOKEN_AUDIENCE } from '../utils/enums'
import LogoutUser from '../utils/LogoutUser'

type Props = RouteProps & {
	layout?: React.ComponentType<any>
	className?: string
	logoutUser?: boolean
	skipRedirectToLoginPage?: boolean
	showLegacyDocumentsLinks?: boolean
}

const CreatePasswordRoute = (props: Props) => {
	// 't' is query param representing JWT token
	const { t } = qs.parse(document.location.search, { ignoreQueryPrefix: true })
	const indexRedirect = <Navigate to={i18next.t('paths:index')} />

	try {
		const payload = decode(t as string)
		const aud = get(payload, 'aud')

		// set new password in cases: completing the registration or forgotten password
		if (aud === TOKEN_AUDIENCE.FORGOTTEN_PASSWORD || aud === TOKEN_AUDIENCE.INVITATION) {
			return <BaseRoute {...props} token={t} />
		}
	} catch {
		return indexRedirect
	}

	return indexRedirect
}

const CreatePasswordRouteWrapper = (props: Props) => {
	return (
		<LogoutUser skipRedirectToLoginPage={props.skipRedirectToLoginPage}>
			<CreatePasswordRoute {...props} />
		</LogoutUser>
	)
}

export default CreatePasswordRouteWrapper
