/* eslint-disable no-underscore-dangle */
import React, { PropsWithChildren, useEffect } from 'react'
import cx from 'classnames'
import { useLocation, useNavigate, Link } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button } from 'antd'
import * as CookieConsent from 'vanilla-cookieconsent'

// components
import LanguagePicker from '../components/LanguagePicker'

// assets
import MdLogo from '../assets/images/md-device-logo.svg?react'
import SmLogo from '../assets/images/sm-device-logo.svg?react'
import SidebarFull from '../assets/images/public-sidebar-full.png'
import SidebarThin from '../assets/images/public-sidebar-thin.png'
import BackButtonIcon from '../assets/icons/back-icon.svg?react'

// redux
import { getConfig } from '../reducers/config/configActions'

// hooks
import useCookieConsentBar from '../hooks/useCookieConsentBar'

// env
import envConfig from '../config'

// utils
import { DATA_LAYER_EVENTS, GA_DROPDOWN_NAME, GA_SCREEN_NAME } from '../utils/dataLayerEnums'
import { pushEventToDataLayer } from '../utils/dataLayer'

type Props = {
	className?: string
	showBackButton?: boolean
	showLegacyDocumentsLinks?: boolean
	gaEvents?: {
		changeLanguage?: {
			screenName?: GA_SCREEN_NAME
		}
	}
} & PropsWithChildren

const PublicLayout = (props: Props) => {
	const { className, showBackButton = false, showLegacyDocumentsLinks = false, children, gaEvents } = props
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [t] = useTranslation()
	const location = useLocation()

	useEffect(() => {
		dispatch(getConfig())
	}, [dispatch])

	useCookieConsentBar()

	return (
		<>
			<div className={cx(className, 'min-h-[100vh] h-full grid place-items-center w-screen bg-notino-grayLighter md:bg-notino-grayLight mx-auto relative')}>
				<div className={'w-full max-w-[440px] md:max-w-full md:w-auto md:flex md:shadow-[0_10px_25px_10px_#0000001a]'}>
					{/* SIDEBAR */}
					<div
						className={`hidden flex-shrink-0 bg-top bg-no-repeat md:block w-[248px] min-h-[760px] bg-white bg-[length:100%_auto] bg-[image:var(--sidebar-image-url-md)] xl:w-[640px] xl:bg-[image:var(--sidebar-image-url-xl)]`}
						style={{ '--sidebar-image-url-md': `url(${SidebarThin})`, '--sidebar-image-url-xl': `url(${SidebarFull})` } as React.CSSProperties}
					/>
					{/* MAIN CONTENT */}
					<div className='flex py-2 px-4 flex-col items-center w-full md:w-[470px] xl:w-[640px] md:px-6 md:pt-6 md:pb-4 bg-notino-grayLighter'>
						<SmLogo className={'mb-4 md:hidden'} />
						<MdLogo className={'mb-4 hidden md:block h-14'} />
						<div className={'w-full mx-auto'}>{children}</div>
						{/* MAIN CONTENT - FOOTER */}
						<div className={'w-full flex gap-4 items-center pr-28 pb-0 mt-auto sticky bottom-4 md:relative md:bottom-0'}>
							{showBackButton && (
								<Button
									onClick={() => navigate(location?.state?.from || t('paths:index'))}
									type={'primary'}
									size={'small'}
									className={'noti-btn noti-public-back-btn'}
									icon={<BackButtonIcon />}
								>
									{t('loc:Späť')}
								</Button>
							)}
							{showLegacyDocumentsLinks ? (
								<ul className={'m-0 p-0 list-none flex items-center flex-wrap'}>
									<li>
										<Link
											to={t('paths:terms-of-use')}
											className={'text-xs text-gray-darker no-underline hover:text-notino-pink mr-3'}
											style={{ transition: 'all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)' }}
										>
											{t('loc:Podmienky používania')}
										</Link>
									</li>
									<li>
										<Link
											to={t('paths:privacy-policy')}
											className={'text-xs text-gray-darker no-underline hover:text-notino-pink mr-3'}
											style={{ transition: 'all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)' }}
										>
											{t('loc:Zásady spracovania osobných údajov')}
										</Link>
									</li>
									{envConfig.VITE_NOTINO_GTM_ID && (
										<li>
											<button
												type={'button'}
												id='cookie-consent-button'
												className={'text-xs bg-transparent p-0 cursor-pointer border-none text-gray-darker no-underline hover:text-notino-pink'}
												style={{ transition: 'all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)' }}
												onClick={CookieConsent.showPreferences}
											>
												{t('loc:Nastavenie cookies')}
											</button>
										</li>
									)}
								</ul>
							) : null}
							<LanguagePicker
								className='-bottom-1 right-0 absolute mb-0 pb-0'
								onDropdownVisibleChange={(open) => {
									if (open && gaEvents?.changeLanguage?.screenName) {
										pushEventToDataLayer({
											event: DATA_LAYER_EVENTS.OPEN_DROPDOWN,
											dropdown_name: GA_DROPDOWN_NAME.LANGUAGE_SELECTION,
											screen_name: gaEvents.changeLanguage.screenName
										})
									}
								}}
								onSelect={(value) => {
									if (gaEvents?.changeLanguage?.screenName) {
										pushEventToDataLayer({
											event: DATA_LAYER_EVENTS.SELECT_DROPDOWN_MENU_OPTION,
											dropdown_name: GA_DROPDOWN_NAME.LANGUAGE_SELECTION,
											screen_name: gaEvents.changeLanguage.screenName,
											selected_option: value
										})
									}
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default PublicLayout
