import React, { useState } from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import CopyIcon from '../../assets/icons/copyable-icon.svg?react'

interface CopyButtonProps {
	link: string
	title: string
}

const CopyToClipboardButton = ({ link, title }: CopyButtonProps) => {
	const [isCopied, setIsCopied] = useState(false)
	const [t] = useTranslation()

	const handleCopy = async () => {
		try {
			await navigator.clipboard.writeText(link)
			setIsCopied(true)
			setTimeout(() => setIsCopied(false), 2000) // Reset after 2 seconds
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Failed to copy:', error)
		}
	}

	return (
		<Button icon={<CopyIcon className={'medium-icon'} />} type={'link'} className={'noti-btn noti-btn-copy'} onClick={handleCopy} disabled={isCopied}>
			{isCopied ? t('loc:Odkaz skopírovaný') : title}
		</Button>
	)
}

export default CopyToClipboardButton
