import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, RouteProps, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'

// redux
import { RootState } from '../reducers'
import { getCurrentUser } from '../reducers/users/userActions'

// utils
import { isLoggedIn } from '../utils/auth'

// components
import IdleTimer from './components/IdleTimer'
import GlobalLoading from '../atoms/GlobalLoading'
import BaseRoute from './BaseRoute'

type Props = {
	layout?: React.ComponentType<any>
} & RouteProps

const AuthRoute = (props: Props) => {
	const [t] = useTranslation()
	const { pathname, search } = useLocation()
	const dispatch = useDispatch()
	const [loadingUser, setLoadingUser] = useState(true)

	const currentUser = useSelector((state: RootState) => state.user.authUser)
	const isActivated = currentUser.data?.activateAt

	useEffect(() => {
		;(async () => {
			if (isLoggedIn()) {
				const { data } = await dispatch(getCurrentUser())
				Sentry.setUser(data?.id ? { id: data.id } : null)
			}
			setLoadingUser(false)
		})()
		return () => {
			Sentry.setUser(null)
		}
	}, [dispatch])

	if (!isLoggedIn()) {
		/**
		 * v pripade, ze uzivatel nebol prihlaseny a v prehliadaci zvolil nejaku autorizovanu URL, tak ho to redirectne na login pagu
		 * do statu si ulozime URL, z ktorej bol presmerovany a nasledne po uspesnom prihlaseny presmerujeme naspät
		 */
		return (
			<Navigate
				to={{
					pathname: t('paths:login')
				}}
				state={{ redirectFrom: pathname + search }}
			/>
		)
	}

	if (loadingUser) {
		return <GlobalLoading />
	}

	if (!isActivated && pathname !== t('paths:activation')) {
		return <Navigate to={t('paths:activation')} />
	}

	return (
		<>
			{/* NOTE: prevent to have cached app version */}
			{import.meta.env.PROD && <IdleTimer />}
			<BaseRoute {...props} />
		</>
	)
}

export default AuthRoute
