import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { compose } from 'redux'

// components
import LoginAsPartnerForm from './components/LoginAsPartnerForm'

// types
import { ILoginAsPartnerForm } from '../../types/interfaces'

// actions
import { processAuthorizationResult } from '../../reducers/users/userActions'

// utils
import { postReq } from '../../utils/request'
import { withPermissions } from '../../utils/Permissions'
import { PERMISSION } from '../../utils/enums'
import { RESET_STORE } from '../../reducers/generalTypes'

const LoginAsPartnerPage = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [t] = useTranslation()

	const handleLoginSubmit = async (values: ILoginAsPartnerForm) => {
		try {
			const { data } = await postReq('/api/b2b/admin/auth/login-as-partner', {
				params: {},
				reqBody: { email: values.email || '' },
				customConfig: { skipRedirectToLoginPage: true, skip401Logout: true }
			})
			await dispatch({
				type: RESET_STORE
			})
			await dispatch(processAuthorizationResult(data, t('paths:index'), true))
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		}
	}

	const onSkipLoginAsPartner = () => {
		navigate(t('paths:index'))
	}

	return (
		<div className='mt-16 max-w-80 w-full mx-auto'>
			<h3>{t('loc:Zadajte e-mail partnera')}</h3>
			<LoginAsPartnerForm onSubmit={handleLoginSubmit} onSkipLoginAsPartner={onSkipLoginAsPartner} />
		</div>
	)
}

export default compose(withPermissions([PERMISSION.NOTINO_SUPER_ADMIN]))(LoginAsPartnerPage)
