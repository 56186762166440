import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Modal, Row, Spin, Tooltip } from 'antd'
import { destroy, getFormInitialValues, getFormValues, initialize, isInvalid, isPristine, isValid, reset, submit } from 'redux-form'
import cx from 'classnames'
import { useNavigate } from 'react-router'
import { isEmpty } from 'lodash'

// components
import dayjs from 'dayjs'
import DeleteButton from '../../components/DeleteButton'
import SalonForm from './components/forms/SalonForm'
import OpenHoursNoteModal from '../../components/OpeningHours/OpenHoursNoteModal'
import { scrollToTopFn } from '../../components/ScrollToTop'
import NoteForm from './components/forms/NoteForm'
import SalonApprovalModal from './components/modals/SalonApprovalModal'
import NotinoUserForm from './components/forms/NotinoUserForm'
import Voucher from './components/Voucher'

// enums
import { DELETE_BUTTON_ID, FORM, PERMISSION, SALON_STATES, STRINGS, SALON_CREATE_TYPE, SUBMIT_BUTTON_ID, PAGE, SALON_DETAIL_FORM_SECTION } from '../../utils/enums'

// reducers
import { ISelectedSalonPayload } from '../../reducers/selectedSalon/selectedSalonActions'
import { getCurrentUser } from '../../reducers/users/userActions'
import { clearSalonNonCheckedChanges, getSalonNonCheckedChanges } from '../../reducers/salons/salonsActions'

// types
import { ILoadingAndFailure, INoteForm, INoteModal, INotinoUserForm, ISalonForm, SalonPageProps } from '../../types/interfaces'
import { RootState } from '../../reducers'

// utils
import { deleteReq, patchReq } from '../../utils/request'
import Permissions from '../../utils/Permissions'
import { formFieldID, getAssignedUserLabel } from '../../utils/helper'
import { getSalonDataForSubmission } from './components/salonUtils'
import { DATA_LAYER_EVENTS, GA_SCREEN_CLASS, GA_SCREEN_NAME } from '../../utils/dataLayerEnums'
import { SALON_DETAIL_CHANGES, SalonDetailSectionChanges, getGaFormWithSectionsChanges, pushEventToDataLayer } from '../../utils/dataLayer'

// assets
import CloseIcon from '../../assets/icons/close-icon-modal.svg?react'
import EyeoffIcon from '../../assets/icons/eye-hidden-icon.svg?react'
import CheckIcon from '../../assets/icons/check-icon.svg?react'
import CloseCircleIcon from '../../assets/icons/close-circle-icon.svg?react'
import EditIcon from '../../assets/icons/edit-icon.svg?react'
import ClockIcon from '../../assets/icons/clock-icon.svg?react'

// hooks
import usePushScreenInfoToDataLayer from '../../hooks/usePushScreenInfoToDataLayer'

interface EditSalonPageProps extends SalonPageProps {
	salonID: string
	isDeletedSalon: boolean
	backUrl?: string
	salon: ISelectedSalonPayload & ILoadingAndFailure
	parentPath: string
	fetchSalonData: (salonID: string | null, initializeForm?: boolean) => Promise<void>
}

const pendingStates: string[] = [SALON_STATES.NOT_PUBLISHED_PENDING, SALON_STATES.PUBLISHED_PENDING]

const EditSalonPage = (props: EditSalonPageProps) => {
	const navigate = useNavigate()
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const { salonID, isNotinoUser, backUrl, configLoading, authUser, fetchSalonData, isDeletedSalon, salon } = props
	const [submitting, setSubmitting] = useState<boolean>(false)
	const [isSendingConfRequest, setIsSendingConfRequest] = useState<boolean>(false)
	const [isRemoving, setIsRemoving] = useState<boolean>(false)
	const [openingHoursModalVisible, setOpeningHoursModalVisible] = useState<boolean>(false)
	const [modalConfig, setModalConfig] = useState<INoteModal>({ title: '', fieldPlaceholderText: '', onSubmit: undefined, visible: false })
	const [approvalModalVisible, setApprovalModalVisible] = useState(false)
	const [visibleNotinoUserModal, setVisibleNotinoUserModal] = useState(false)

	const isFormPristine = useSelector(isPristine(FORM.SALON))
	const nonCheckedChanges = useSelector((state: RootState) => state.salons.salonNonCheckedChanges)
	const initialFormValues = useSelector((state: RootState) => getFormInitialValues(FORM.SALON)(state)) as ISalonForm
	const formValues = useSelector((state: RootState) => getFormValues(FORM.SALON)(state)) as ISalonForm
	const isFormValid = useSelector(isValid(FORM.SALON))

	const isSubmittingData = submitting || isRemoving || isSendingConfRequest

	const isLoading = salon.isLoading || configLoading || authUser?.isLoading || isSubmittingData
	const isPendingPublication = (salon.data && pendingStates.includes(salon.data.state)) || false
	const isPublished = salon.data?.isPublished

	const declinedSalon = salon.data?.state === SALON_STATES.NOT_PUBLISHED_DECLINED || salon.data?.state === SALON_STATES.PUBLISHED_DECLINED
	const hiddenSalon = salon.data?.state === SALON_STATES.NOT_PUBLISHED && salon.data?.publicationDeclineReason
	const isBasic = salon.data?.createType === SALON_CREATE_TYPE.BASIC

	const b2bVoucher = salon.data?.b2bVoucher
	const b2bVoucher2 = salon.data?.b2bVoucher2

	const assignedUserLabel = getAssignedUserLabel(salon.data?.assignedUser)

	const disabledForm = isDeletedSalon || (isPendingPublication && !isNotinoUser)

	// Google Analytics initial events
	usePushScreenInfoToDataLayer(GA_SCREEN_NAME.SALON_DETAIL, GA_SCREEN_CLASS.SALON_DETAIL)

	useEffect(() => {
		return () => {
			localStorage.setItem(`hide-${PAGE.SALON_DETAIL}-badge`, 'true')
		}
	}, [])

	useEffect(() => {
		if (isNotinoUser && !isDeletedSalon && salonID === salon.data?.id) {
			dispatch(getSalonNonCheckedChanges(salonID))
		} else {
			dispatch(clearSalonNonCheckedChanges())
		}
	}, [isNotinoUser, dispatch, salonID, isDeletedSalon, salon.data?.id])

	const handleSubmit = async (data: ISalonForm) => {
		try {
			setSubmitting(true)
			await patchReq('/api/b2b/admin/salons/{salonID}', { params: { path: { salonID } }, reqBody: getSalonDataForSubmission(data, isNotinoUser) })
			await fetchSalonData(salonID)
			if (isNotinoUser) {
				await dispatch(getSalonNonCheckedChanges(salonID))
			}
		} catch (error: any) {
			// eslint-disable-next-line no-console
			console.error(error.message)
		} finally {
			setSubmitting(false)
			scrollToTopFn()
		}
	}

	const deleteSalon = async () => {
		if (isRemoving) {
			return
		}
		try {
			setIsRemoving(true)
			await deleteReq('/api/b2b/admin/salons/{salonID}', { params: { path: { salonID } }, reqBody: {} })
			if (isNotinoUser) {
				navigate(backUrl as string)
			} else {
				// check if there are any other salons assigned to user and redirect user to first of them
				const { data } = await dispatch(getCurrentUser())
				const salonToRedirect = (data?.salons || [])[0]
				if (salonToRedirect) {
					navigate(`${t('paths:salons')}/${salonToRedirect.id}`)
				} else {
					// otherwise redirect user to dashboard
					await fetchSalonData(null)
					navigate(t('paths:index'))
				}
			}
		} catch (error: any) {
			// eslint-disable-next-line no-console
			console.error(error.message)
		} finally {
			setIsRemoving(false)
		}
	}

	const deleteOpenHoursNote = async () => {
		if (isRemoving) {
			return
		}

		setIsRemoving(true)
		try {
			await patchReq('/api/b2b/admin/salons/{salonID}/open-hours-note', { params: { path: { salonID } }, reqBody: { openingHoursNote: null } })
			dispatch(reset(FORM.OPEN_HOURS_NOTE))
			await fetchSalonData(salonID, false)
		} catch (error: any) {
			// eslint-disable-next-line no-console
			console.error(error.message)
		} finally {
			setIsRemoving(false)
		}
	}

	const deleteAssignedUser = async () => {
		if (isRemoving) {
			return
		}

		setIsRemoving(true)
		try {
			await patchReq('/api/b2b/admin/salons/{salonID}/assigned-user', { params: { path: { salonID } }, reqBody: { assignedUserID: null } })
			dispatch(destroy(FORM.NOTINO_USER))
			await fetchSalonData(salonID, false)
		} catch (error: any) {
			// eslint-disable-next-line no-console
			console.error(error.message)
		} finally {
			setIsRemoving(false)
		}
	}

	const unPublishSalon = async (formData: INoteForm) => {
		if (submitting) {
			return
		}

		setSubmitting(true)
		try {
			await patchReq('/api/b2b/admin/salons/{salonID}/unpublish', { params: { path: { salonID } }, reqBody: { reason: formData.note } })
			await fetchSalonData(salonID)
		} catch (error: any) {
			// eslint-disable-next-line no-console
			console.error(error.message)
		} finally {
			setModalConfig({
				title: '',
				fieldPlaceholderText: '',
				visible: false,
				onSubmit: undefined
			})
			setSubmitting(false)
		}
	}

	const sendConfirmationRequest = async () => {
		if (isSendingConfRequest) {
			return
		}

		setIsSendingConfRequest(true)
		try {
			await patchReq('/api/b2b/admin/salons/{salonID}/request-publication', { params: { path: { salonID } }, reqBody: {} })
			await fetchSalonData(salonID)
			setApprovalModalVisible(false)
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		} finally {
			setIsSendingConfRequest(false)
		}
	}

	const resolveConfirmationRequest = async (formData?: INoteForm) => {
		if (isSendingConfRequest) {
			return
		}

		setIsSendingConfRequest(true)
		try {
			await patchReq('/api/b2b/admin/salons/{salonID}/resolve-publication', {
				params: { path: { salonID } },
				reqBody: { approve: !formData?.note, reason: formData?.note || undefined }
			})
			await fetchSalonData(salonID)
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		} finally {
			// close modal with note
			if (formData) {
				// reset form
				dispatch(reset(FORM.NOTE))
				setModalConfig({
					...modalConfig,
					visible: false
				})
			}
			setIsSendingConfRequest(false)
		}
	}

	const isFormInvalid = useSelector(isInvalid(FORM.SALON))

	const verifySalonChanges = async () => {
		try {
			setSubmitting(true)

			if (isFormInvalid) {
				// invalid form will show error notification on submit, and won't make a request to BE
				await dispatch(submit(FORM.SALON))
				return
			}

			// save unsaved changes
			if (!isFormPristine && formValues) {
				await dispatch(submit(FORM.SALON))
			}

			await patchReq('/api/b2b/admin/salons/{salonID}/check', { params: { path: { salonID } }, reqBody: {} })
			await dispatch(getSalonNonCheckedChanges(salonID))
		} catch (error: any) {
			// eslint-disable-next-line no-console
			console.error(error.message)
		} finally {
			setSubmitting(false)
		}
	}

	const onOpenHoursNoteModalClose = () => {
		setOpeningHoursModalVisible(false)
		fetchSalonData(salonID, false)
	}

	// render buttons on footer based on salon statuses
	const renderContentFooter = () => {
		if (isDeletedSalon) {
			return null
		}

		const hideSalonButton = (
			<Permissions
				allowed={[PERMISSION.NOTINO, PERMISSION.PARTNER_ADMIN, PERMISSION.SALON_UPDATE]}
				render={(hasPermission, { openForbiddenModal }) => (
					<Button
						id={formFieldID(FORM.SALON, 'hide-salon')}
						type={'dashed'}
						size={'middle'}
						icon={<EyeoffIcon className={'medium-icon'} color={'#000'} />}
						className={'noti-btn m-regular w-full md:w-auto'}
						onClick={(e) => {
							if (hasPermission) {
								setModalConfig({
									title: t('loc:Skrytie salónu'),
									fieldPlaceholderText: t('loc:Sem napíšte dôvod skrytia'),
									visible: true,
									onSubmit: unPublishSalon
								})
							} else {
								e.preventDefault()
								openForbiddenModal()
							}
						}}
						disabled={isLoading || isDeletedSalon}
						loading={submitting}
					>
						{t('loc:Skryť salón')}
					</Button>
				)}
			/>
		)

		// Workaround for disabled button inside tooltip: https://github.com/react-component/tooltip/issues/18
		const disabledRequestApprovalButton = isLoading || isDeletedSalon || (!isFormPristine && !isPendingPublication && (!isPublished || isBasic))
		const requestApprovalButton = (
			<Tooltip
				title={
					disabledRequestApprovalButton
						? t('loc:V salóne boli vykonané zmeny, ktoré nie sú uložené. Pred požiadaním o schválenie je potrebné zmeny najprv uložiť.')
						: null
				}
				getPopupContainer={() => document.getElementById('content-footer-container') || document.body}
			>
				<span className={cx('w-full md:w-auto', { 'cursor-not-allowed': disabledRequestApprovalButton })}>
					<Button
						id={formFieldID(FORM.SALON, 'request-publication')}
						type={'dashed'}
						size={'middle'}
						className={cx('noti-btn m-regular w-full md:w-auto', {
							'pointer-events-none': disabledRequestApprovalButton
						})}
						disabled={disabledRequestApprovalButton}
						onClick={() => {
							pushEventToDataLayer({
								event: DATA_LAYER_EVENTS.CLICK_BUTTON,
								button_name: 'request_approval',
								screen_name: GA_SCREEN_NAME.SALON_DETAIL
							})

							fetchSalonData(salonID, false)
							setApprovalModalVisible(true)
						}}
						loading={submitting}
					>
						{t('loc:Požiadať o schválenie')}
					</Button>
				</span>
			</Tooltip>
		)

		let verifySalonChangesButtonLabel = t('loc:Verifikovať zmeny')
		const disabledVerifyChangesButton = isLoading || isEmpty(nonCheckedChanges.data?.changes)

		if (
			isEmpty(nonCheckedChanges.data?.changes) &&
			nonCheckedChanges.data?.checkedAt &&
			nonCheckedChanges.data?.lastUpdatedAt &&
			dayjs(nonCheckedChanges.data.checkedAt).isSameOrAfter(nonCheckedChanges.data.lastUpdatedAt)
		) {
			verifySalonChangesButtonLabel = t('loc:Zmeny verifikované')
		}

		const verifySalonChangesButton = (
			<span className={cx('w-full md:w-auto', { 'cursor-not-allowed': disabledVerifyChangesButton })}>
				<Button
					id={formFieldID(FORM.SALON, 'verify-salon-changes')}
					type={'primary'}
					size={'middle'}
					icon={<CheckIcon className={'medium-icon'} />}
					className={cx('noti-btn noti-verify-changes-btn m-regular w-full md:w-auto', {
						'pointer-events-none': disabledRequestApprovalButton
					})}
					onClick={verifySalonChanges}
					disabled={disabledVerifyChangesButton}
					loading={submitting}
				>
					{verifySalonChangesButtonLabel}
				</Button>
			</span>
		)

		return (
			<div className={'content-footer'} id={'content-footer-container'}>
				<Row className={'w-full gap-2 md:items-center flex-col md:flex-row md:justify-between md:flex-nowrap'}>
					<DeleteButton
						permissions={[PERMISSION.NOTINO, PERMISSION.PARTNER_ADMIN, PERMISSION.SALON_DELETE]}
						className={'w-full md:w-auto'}
						onConfirm={deleteSalon}
						entityName={t('loc:salón')}
						type={'default'}
						getPopupContainer={() => document.getElementById('content-footer-container') || document.body}
						disabled={isLoading || isDeletedSalon}
						id={formFieldID(FORM.SALON, DELETE_BUTTON_ID)}
						onClick={() => {
							pushEventToDataLayer({
								event: DATA_LAYER_EVENTS.CLICK_BUTTON,
								button_name: 'remove_salon',
								screen_name: GA_SCREEN_NAME.SALON_DETAIL
							})
						}}
					/>
					<Row className={'gap-2 justify-end w-full'}>
						{(() => {
							// order of cases is important to show correct buttons
							switch (true) {
								// pending approval
								case isPendingPublication:
									return isNotinoUser ? verifySalonChangesButton : null
								// published and not pending
								case isPublished && !isPendingPublication && !isBasic:
									return isNotinoUser ? (
										<>
											{verifySalonChangesButton}
											{hideSalonButton}
										</>
									) : null
								// default
								default:
									return (
										<>
											{isNotinoUser && verifySalonChangesButton}
											{requestApprovalButton}
										</>
									)
							}
						})()}
						<Permissions
							allowed={[PERMISSION.PARTNER_ADMIN, PERMISSION.SALON_UPDATE]}
							render={(hasPermission, { openForbiddenModal }) => (
								<Button
									id={formFieldID(FORM.SALON, SUBMIT_BUTTON_ID)}
									type={'primary'}
									icon={<EditIcon />}
									size={'middle'}
									className={'noti-btn m-regular w-full md:w-auto'}
									htmlType={'submit'}
									onClick={(e) => {
										pushEventToDataLayer<{ changes: SalonDetailSectionChanges[]; isSuccess: boolean }>({
											event: DATA_LAYER_EVENTS.CLICK_BUTTON,
											button_name: 'save_salon_info',
											screen_name: GA_SCREEN_NAME.SALON_DETAIL,
											isSuccess: isFormValid,
											changes: getGaFormWithSectionsChanges<SALON_DETAIL_FORM_SECTION, ISalonForm | undefined, SalonDetailSectionChanges>(
												SALON_DETAIL_CHANGES,
												initialFormValues,
												formValues
											)
										})

										if (hasPermission) {
											dispatch(submit(FORM.SALON))
										} else {
											e.preventDefault()
											openForbiddenModal()
										}
									}}
									// if is salon pending approval and user is not Admin
									disabled={isLoading || isDeletedSalon || isFormPristine || (!isPublished && isPendingPublication && !isNotinoUser)}
									loading={submitting}
								>
									{t('loc:Uložiť')}
								</Button>
							)}
						/>
					</Row>
				</Row>
			</div>
		)
	}

	const infoMessage = useMemo(() => {
		let message: string | null

		// order of cases is important to show correct message
		switch (true) {
			case isDeletedSalon:
				message = null
				break
			case isPendingPublication && !isNotinoUser:
				message = t('loc:Salón čaká na schválenie zmien. Údaje salónu, po túto dobu nie je možné editovať.')
				break
			case !isPendingPublication && !isFormPristine && (!isPublished || isBasic):
				message = t('loc:V salóne boli vykonané zmeny, ktoré nie sú uložené. Pred požiadaním o schválenie je potrebné zmeny najprv uložiť.')
				break
			case salon.data?.state === SALON_STATES.NOT_PUBLISHED || salon.data?.state === SALON_STATES.NOT_PUBLISHED_DECLINED:
				message = t('loc:Ak chcete salón publikovať, je potrebné požiadať o jeho schválenie.')
				break
			case !isPendingPublication && isPublished && isBasic:
				message = t('loc:V salóne sa nachádzajú nepublikované zmeny, ktoré je pred zverejnením potrebné schváliť administrátorom.')
				break
			default:
				message = null
		}

		if (message) {
			return <Alert message={message} showIcon type={'warning'} className={'noti-alert w-full'} />
		}

		return null
	}, [isPendingPublication, isFormPristine, isPublished, isDeletedSalon, t, salon.data?.state, isNotinoUser, isBasic])

	const declinedSalonMessage = useMemo(
		() =>
			declinedSalon ? (
				<Alert
					message={
						<>
							<strong className={'block'}>{`${t('loc:Salón bol zamietnutý z dôvodu')}:`}</strong>
							<p className={'whitespace-pre-wrap m-0'}>
								{salon?.data?.publicationDeclineReason ? `"${salon?.data?.publicationDeclineReason}"` : t('loc:Bez udania dôvodu.')}
							</p>
						</>
					}
					showIcon
					type={'error'}
					className={'noti-alert mb-4'}
				/>
			) : undefined,
		[t, salon?.data?.publicationDeclineReason, declinedSalon]
	)

	const hiddenSalonMessage = useMemo(
		() =>
			hiddenSalon ? (
				<Alert
					message={
						<>
							<strong className={'block'}>{`${t('loc:Salón bol skrytý z dôvodu')}:`}</strong>
							<p className={'whitespace-pre-wrap m-0'}>{`"${salon?.data?.publicationDeclineReason}"`}</p>
						</>
					}
					showIcon
					type={'error'}
					className={'noti-alert mb-4'}
				/>
			) : undefined,
		[t, salon?.data?.publicationDeclineReason, hiddenSalon]
	)

	const renderContentHeaderPartner = () => infoMessage && <div className={'content-header z-10'}>{infoMessage}</div>

	const renderContentHeaderAdmin = () =>
		(infoMessage || isPendingPublication) && (
			<div className={cx('content-header flex-col gap-2')}>
				{isPendingPublication && (
					<Permissions
						allowed={[PERMISSION.SALON_PUBLICATION_RESOLVE]}
						render={(hasPermission, { openForbiddenModal }) => (
							<div className={'bg-notino-grayLighter px-6 py-4 w-full rounded flex gap-2 items-center flex-wrap'}>
								<div className={'flex-auto'}>
									<h2 className={'text-base text-notino-black font-semibold flex items-start gap-1 mb-1'}>
										<ClockIcon className={'w-4 h-4 text-notino-black flex-shrink-0 mt-1'} />
										{t('loc:Salón čaká na schválenie')}
									</h2>
									<span className={'text-notino-grayDarker text-sm'}>{t('loc:Pred potvrdením prosím skontrolujte všetky údaje salónu.')}</span>
								</div>
								<div className={'flex items-center gap-4 flex-wrap'}>
									<Button
										id={formFieldID(FORM.SALON, 'decline-salon')}
										icon={<CloseCircleIcon className={'medium-icon'} />}
										size={'middle'}
										danger
										className={'ant-btn noti-btn m-regular hover:shadow-none'}
										onClick={(e) => {
											if (hasPermission) {
												setModalConfig({
													title: t('loc:Dôvod zamietnutia'),
													fieldPlaceholderText: t('loc:Sem napíšte dôvod zamietnutia'),
													visible: true,
													onSubmit: resolveConfirmationRequest
												})
											} else {
												e.preventDefault()
												openForbiddenModal()
											}
										}}
										disabled={submitting}
										loading={submitting}
									>
										{t('loc:Zamietnuť')}
									</Button>
									<Button
										id={formFieldID(FORM.SALON, 'accept-salon')}
										type={'primary'}
										icon={<CheckIcon color={'#fff'} className={'medium-icon'} />}
										size={'middle'}
										className={'noti-btn m-regular'}
										onClick={(e) => {
											if (hasPermission) {
												resolveConfirmationRequest()
											} else {
												e.preventDefault()
												openForbiddenModal()
											}
										}}
										disabled={submitting}
										loading={submitting}
									>
										{t('loc:Potvrdiť')}
									</Button>
								</div>
							</div>
						)}
					/>
				)}
				{infoMessage}
			</div>
		)

	const approvalButtonDisabled = !salon?.data?.hasAllRequiredSalonApprovalData || isDeletedSalon || isSubmittingData || salon?.isLoading || isPendingPublication

	const getApprovalButtonTooltipMessage = () => {
		if (!salon?.data?.hasAllRequiredSalonApprovalData && !isPendingPublication) {
			return t('loc:Žiadosť o schválenie nie je možné odoslať, pretože nie sú vyplnené všetky potrebné údaje zo zoznamu nižšie.')
		}
		if (isPendingPublication) {
			return t('loc:Žiadosť o schválenie už bola odoslaná. Počkajte prosím na jej vybavenie.')
		}
		return null
	}
	const onSubmitNotinoUser = async (values?: INotinoUserForm) => {
		try {
			// fallback for allowClear true if user removed assigned user send null value
			// TODO: pozriet sa na typy a zbavit sa castovania
			await patchReq('/api/b2b/admin/salons/{salonID}/assigned-user', {
				params: { path: { salonID } },
				reqBody: { assignedUserID: (values?.assignedUser?.key as string) || null }
			})
			setVisibleNotinoUserModal(false)
			await fetchSalonData(salonID, false)
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	return (
		<>
			<div className='content-body'>
				<Spin spinning={isLoading}>
					{isNotinoUser ? renderContentHeaderAdmin() : renderContentHeaderPartner()}
					{declinedSalonMessage}
					{hiddenSalonMessage}
					<SalonForm
						salonID={salonID}
						onSubmit={handleSubmit}
						// edit mode is turned off if salon is in approval process and user is not admin or is deleted 'read mode' only
						disabledForm={isDeletedSalon || (isPendingPublication && !isNotinoUser)}
						deletedSalon={isDeletedSalon}
						salonData={salon?.data}
						nonCheckedChanges={nonCheckedChanges.data?.changes}
						notinoUserModalControlButtons={
							isNotinoUser && (
								<Row className={'flex justify-start w-full gap-2 pb-4'}>
									{salon?.data?.assignedUser?.id ? (
										<>
											<div className='w-full'>
												<h4>{t('loc:Priradený Notino používateľ')}</h4>
												<i className='block text-base'>{assignedUserLabel}</i>
											</div>
											<Button
												type={'primary'}
												size={'middle'}
												className={'noti-btn m-regular'}
												onClick={() => {
													setVisibleNotinoUserModal(true)
													dispatch(
														initialize(FORM.NOTINO_USER, {
															assignedUser: { key: salon.data?.assignedUser?.id, value: salon.data?.assignedUser?.id, label: assignedUserLabel }
														})
													)
												}}
												disabled={isDeletedSalon}
											>
												{STRINGS(t).edit(t('loc:notino používateľa'))}
											</Button>
											<DeleteButton onConfirm={deleteAssignedUser} entityName={t('loc:notino používateľa')} disabled={isDeletedSalon} />
										</>
									) : (
										<Button
											type={'primary'}
											size={'middle'}
											className={'noti-btn m-regular'}
											onClick={() => setVisibleNotinoUserModal(true)}
											disabled={isDeletedSalon}
										>
											{STRINGS(t).addRecord(t('loc:notino používateľa'))}
										</Button>
									)}
								</Row>
							)
						}
						noteModalControlButtons={
							<Row className={'flex justify-start w-full mt-4 gap-2'}>
								{salon?.data?.openingHoursNote ? (
									<>
										<div className='w-full'>
											<h4>{t('loc:Poznámka pre otváracie hodiny')}</h4>
											<i
												className={cx('block mb-2 text-base opening-hours-note-text', {
													'to-check-changes': isNotinoUser && nonCheckedChanges.data?.changes?.['salonData.openingHoursNote']?.changed
												})}
											>
												{salon.data.openingHoursNote.note}
											</i>
										</div>
										<Button
											type={'primary'}
											size={'middle'}
											className={'noti-btn m-regular mt-2'}
											onClick={() => setOpeningHoursModalVisible(true)}
											disabled={disabledForm}
										>
											{STRINGS(t).edit(t('loc:poznámku'))}
										</Button>
										<DeleteButton className={'mt-2'} onConfirm={deleteOpenHoursNote} entityName={t('loc:poznámku')} disabled={disabledForm} />
									</>
								) : (
									<Button
										type={'primary'}
										size={'middle'}
										className={'noti-btn m-regular mt-2'}
										onClick={() => setOpeningHoursModalVisible(true)}
										disabled={disabledForm}
									>
										{STRINGS(t).addRecord(t('loc:poznámku'))}
									</Button>
								)}
							</Row>
						}
						vouchers={
							b2bVoucher || b2bVoucher2 ? (
								<>
									<h4 className={'text-lg font-bold text-black mb-2 mt-4'}>{t('loc:Zľavové kupóny')}</h4>
									<div
										className={'grid gap-4'}
										style={{
											gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr)'
										}}
									>
										{b2bVoucher && <Voucher title={t('loc:Kód zľavového kupónu {{number}}', { number: 1 })} voucherCode={b2bVoucher} />}
										{b2bVoucher2 && <Voucher title={t('loc:Kód zľavového kupónu {{number}}', { number: 2 })} voucherCode={b2bVoucher2} />}
									</div>
								</>
							) : null
						}
					/>
					{renderContentFooter()}
				</Spin>
			</div>
			<OpenHoursNoteModal
				title={t('loc:Poznámka pre otváracie hodiny')}
				visible={openingHoursModalVisible}
				salonID={salonID}
				openingHoursNote={salon?.data?.openingHoursNote}
				onClose={onOpenHoursNoteModalClose}
			/>
			<Modal
				key={`${modalConfig.visible}`}
				title={modalConfig.title}
				open={modalConfig.visible}
				onCancel={() =>
					setModalConfig({
						title: '',
						fieldPlaceholderText: '',
						visible: false,
						onSubmit: undefined
					})
				}
				footer={null}
				closeIcon={<CloseIcon />}
			>
				<NoteForm onSubmit={modalConfig.onSubmit} fieldPlaceholderText={modalConfig.fieldPlaceholderText} />
			</Modal>
			<Modal
				title={t('loc:Priradiť Notino používateľa')}
				open={visibleNotinoUserModal}
				onCancel={() => setVisibleNotinoUserModal(false)}
				footer={null}
				closeIcon={<CloseIcon />}
			>
				<NotinoUserForm onSubmit={onSubmitNotinoUser} />
			</Modal>
			<SalonApprovalModal
				visible={approvalModalVisible}
				onCancel={() => setApprovalModalVisible(false)}
				parentPath={`${t('paths:salons')}/${salonID}`}
				submitButton={
					<Permissions
						allowed={[PERMISSION.NOTINO, PERMISSION.PARTNER_ADMIN, PERMISSION.SALON_UPDATE]}
						render={(hasPermission, { openForbiddenModal }) => (
							<Tooltip title={getApprovalButtonTooltipMessage()} getPopupContainer={() => document.querySelector('#noti-approval-modal-content') as HTMLElement}>
								<span className={cx({ 'cursor-not-allowed': approvalButtonDisabled })}>
									<Button
										id={formFieldID(FORM.SALON, 'request-publication-modal')}
										type={'primary'}
										block
										size={'large'}
										className={cx('noti-btn m-regular', {
											'pointer-events-none': approvalButtonDisabled
										})}
										disabled={approvalButtonDisabled}
										onClick={(e) => {
											if (hasPermission) {
												sendConfirmationRequest()
											} else {
												e.preventDefault()
												openForbiddenModal()
											}
										}}
									>
										{t('loc:Požiadať o schválenie')}
									</Button>
								</span>
							</Tooltip>
						)}
					/>
				}
			/>
		</>
	)
}

export default EditSalonPage
